import React from "react";

// Customizable Area Start
import "../assets/css/ViewAllWork.css";
import WithFooter from "../../../components/src/Withfooter.web";
import WithHeader from "../../../components/src/WithHeader.web";
import { settingIcon } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Starts

// Customizable Area End

import DashboardController, { Props } from "./DashboardController";

export class ViewAllWork extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GenCard = () => {
    return (
      <div className="project-box">
        <div className="project-icon">
          <a href="#">
            <img src={settingIcon} alt="asstes" />
          </a>
        </div>
        <div className="project-name">
          <span>Modular Civil Contractor Building Construction Services</span>
        </div>
        <div className="total-projects">
          <h5>
            25 projects available{" "}
            <span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="right-side"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </span>
          </h5>
        </div>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-site project-view">
        <div className="site-wrapper">
          <section className="our-project-section">
            <div className="container">
              <div className="our-project-wrapper">
                <div className="project-head">
                  <div className="title-wrapper">
                    <h2>
                      <Link className="cursor-pointer" to="/Dashboard">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="svg-icon"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                          />
                        </svg>
                      </Link>
                      View all projects{" "}
                    </h2>
                  </div>
                </div>
                <div className="projects-group">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8].map(
                    () => {
                      return this.GenCard();
                    }
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default WithHeader(WithFooter(ViewAllWork));
// Customizable Area End
