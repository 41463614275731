import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  Popover,
  Modal
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  labourApplogo,
  erroricon,
  errorIconblack,
  sucessImg,
  backArrow,
  group2Icon
} from "./assets";
import { configJSON } from "./EmailAccountRegistrationController";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};


// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffcc00",
      contrastText: "#fff"
    }
  }
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;

    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid className={classes.logoGrid} item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.logoBox}>
              <img
                className={classes.logoImg}
                src={labourApplogo}
                alt="labourApplogo"
              />
              <Typography className={classes.labourApptext}>
               {configJSON.logoHeading}
              </Typography>
            </Box>
            <Box className={classes.logoBoxmobile}>
              <img
                className={classes.mobilelogoImg}
                src={group2Icon}
                alt="labourApplogo"
              />

            </Box>
          </Grid>
          <Grid
            className={classes.fieldsGrid}
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.fieldsGridbox}>
              <Typography className={classes.accountText}>
               {configJSON.labelHeading}
              </Typography>
              <Typography className={classes.haveAccounttext}>
               {configJSON.labelnewUsertext}
                <span
                  className={classes.signIntext}
                  data-testid="routeTosignin"
                  onClick={this.navigatetosignin}
                >
                  {configJSON.labelnewRouteusertext}
                </span>
              </Typography>
              <Box className={classes.radioButtonbox}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    data-testid="addRole"
                    name="radio-buttons-group"
                    value={this.state.role}
                    onChange={(e: any) => {
                      this.addRole(e);
                    }}
                  >
                    <FormControlLabel
                      className={classes.supervisorRadiobutton}
                      value="Lead/Supervisor"
                      control={<Radio className={classes.radioButton} />}
                      label="Lead/Supervisor"
                    />
                    <FormControlLabel
                      className={classes.labourRadiobutton}
                      value="Labour/Worker"
                      control={<Radio className={classes.radioButton} />}
                      label="Labour/Worker"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box className={classes.textfieldBox}>
                <Typography className={classes.textfieldTextstyle}>
                  {configJSON. FullName}
                </Typography>
                <Box className={classes.textfieldBlock}>
                  <TextField
                    name="full_name"
                    data-testid="addFullname"
                    onChange={(e: any) => {
                      this.addName(e);
                    }}
                    value={this.state.full_name}
                    className={classes.textField}
                  />
                  {this.state.errors.full_name ? (
                    <img
                      className={classes.erroricon}
                      src={erroricon}
                      data-testid="fullnameModalopen"
                      alt=""
                      onClick={(e: any) => {
                        this.openModalfull_name(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <Popover
                    open={this.state.openModalfull_name}
                    data-testid="fullnameModalclose"
                    onClose={this.closeModalfull_name}
                    className={classes.popover_class}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box className={classes.errorMsgbox}>
                      <img
                        className={classes.errorIconblack}
                        src={errorIconblack}
                        alt=""
                      />
                      <Typography className={classes.errormsgstyle}>
                        {this.state.errors.full_name}
                      </Typography>
                    </Box>
                  </Popover>
                </Box>
              </Box>
              <Box className={classes.textfieldBox}>
                <Typography className={classes.textfieldTextstyle}>
               {configJSON. MobileNumber}
                </Typography>
                <Box className={classes.textfieldBlock}>
                  <TextField
                   className={classes.textFieldClass}
                    name="full_phone_number"
                    type="text"
                    data-testid="addPhonenumber"
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    onKeyDown={this.preventETypeNumber}
                    onChange={(e: any) => {
                      this.addPhonenumber(e);
                    }}
                    value={this.state.full_phone_number}
                   
                  />
                  {this.state.errors.full_phone_number ? (
                    <img
                      className={classes.erroricon}
                      data-testid="phonenumberModalopen"
                      src={erroricon}
                      alt=""
                      onClick={(e: any) => {
                        this.openModalfull_phone_number(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <Popover
                    open={this.state.openModalfull_phone_number}
                    data-testid="phonenumberModalclose"
                    onClose={this.closeModalfull_phone_number}
                    className={classes.popover_class}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box className={classes.errorMsgbox}>
                      <img
                        className={classes.errorIconblack}
                        src={errorIconblack}
                        alt=""
                      />
                      <Typography className={classes.errormsgstyle}>
                        {this.state.errors.full_phone_number}
                      </Typography>
                    </Box>
                  </Popover>
                </Box>
              </Box>
              <Box className={classes.textfieldBox}>
                <Typography className={classes.textfieldTextstyle}>
                  {configJSON.placeHolderEmail}
                </Typography>
                <Box className={classes.textfieldBlock}>
                  <TextField
                    name="email_account"
                    data-testid="addEmail"
                    onChange={(e: any) => {
                      this.addEmail(e);
                    }}
                    value={this.state.email_account}
                    className={classes.textField}
                  />
                  {this.state.errors.email_account ? (
                    <img
                      className={classes.erroricon}
                      data-testid="emailModalopen"
                      src={erroricon}
                      alt=""
                      onClick={(e: any) => {
                        this.openModalemail(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <Popover
                    open={this.state.openModalemail}
                    onClose={this.closeModalemail}
                    data-testid="emailModalclose"
                    className={classes.popover_class}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box className={classes.errorMsgbox}>
                      <img
                        className={classes.errorIconblack}
                        src={errorIconblack}
                        alt=""
                      />
                      <Typography className={classes.errormsgstyle}>
                        {this.state.errors.email_account}
                      </Typography>
                    </Box>
                  </Popover>
                </Box>
              </Box>
              <Box className={classes.textfieldBox}>
                <Typography className={classes.textfieldTextstyle}>
                 {configJSON.Password}
                </Typography>
                <Box className={classes.textfieldBlock}>
                  <TextField
                    name="account_password"
                    data-testid="addPassword"
                    onChange={(e: any) => {
                      this.addPassword(e);
                    }}
                    value={this.state.account_password}
                    className={classes.textField}
                  />
                  {this.state.errors.account_password ? (
                    <img
                      className={classes.erroricon}
                      data-testid="passowrdModalopen"
                      src={erroricon}
                      alt=""
                      onClick={(e: any) => {
                        this.openModalpassword(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <Popover
                    open={this.state.openModalpassword}
                    data-testid="passwordModalclose"
                    onClose={this.closeModalpassword}
                    className={classes.popover_class}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box>
                      <Box className={classes.errorMsgbox}>
                        <img
                          className={classes.errorIconblack}
                          src={errorIconblack}
                          alt=""
                        />
                        <Typography className={classes.errormsgPsdstyle}>
                         {configJSON.PasswordCharacters}
                        </Typography>
                      </Box>
                      <Box className={classes.errorMsgbox}>
                        <img
                          className={classes.errorIconblack}
                          src={errorIconblack}
                          alt=""
                        />
                        <Typography className={classes.errormsgPsdstyle}>
                        {configJSON.PasswordUppercase}
                        </Typography>
                      </Box>
                      <Box className={classes.errorMsgbox}>
                        <img
                          className={classes.errorIconblack}
                          src={errorIconblack}
                          alt=""
                        />
                        <Typography className={classes.errormsgPsdstyle}>
                        {configJSON.PasswordLowercase}
                        </Typography>
                      </Box>
                      <Box className={classes.errorMsgbox}>
                        <img
                          className={classes.errorIconblack}
                          src={errorIconblack}
                          alt=""
                        />
                        <Typography className={classes.errormsgPsdstyle}>
                        {configJSON.PasswordOneNumber}
                        </Typography>
                      </Box>
                      <Box className={classes.errorMsgbox}>
                        <img
                          className={classes.errorIconblack}
                          src={errorIconblack}
                          alt=""
                        />
                        <Typography className={classes.errormsgPsdstyle}>
                        {configJSON.PasswordSpecial}
                        </Typography>
                      </Box>
                    </Box>
                  </Popover>
                </Box>
              </Box>
              <Box className={classes.textfieldBox}>
                <Typography className={classes.textfieldTextstyle}>
                  {configJSON.ConfirmPassword}
                </Typography>
                <Box className={classes.textfieldBlock}>
                  <TextField
                    name="confirm_password"
                    data-testid="addConformpassword"
                    onChange={(e: any) => {
                      this.addConformpassowrd(e);
                    }}
                    value={this.state.confirm_password}
                    className={classes.textField}
                  />
                  {this.state.errors.confirm_password ? (
                    <img
                      className={classes.erroricon}
                      data-testid="conformpasswordModalopen"
                      src={erroricon}
                      alt=""
                      onClick={(e: any) => {
                        this.openModalconfirm_password(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <Popover
                    open={this.state.openModalconfirm_password}
                    data-testid="conformpasswordModalclose"
                    onClose={this.closeModalconfirm_password}
                    className={classes.popover_class}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box className={classes.errorMsgbox}>
                      <img
                        className={classes.errorIconblack}
                        src={errorIconblack}
                        alt=""
                      />
                      <Typography className={classes.errormsgstyle}>
                        {this.state.errors.confirm_password}
                      </Typography>
                    </Box>
                  </Popover>
                </Box>
              </Box>
              <Box className={classes.checkboxStyle}>
                <Checkbox
                  className={classes.checkbox}
                  checked={this.state.isChecked}
                  data-testid="checkBox"
                  onChange={(e: any) => {
                    this.addCheckbox(e);
                  }}
                />
                <Typography
                  className={classes.termsAndconditions}
                  data-testid="navigateTotermsandconditions"
                  onClick={this.openModaltermsandconditions}
                >
                  {configJSON.labelLegalTermCondition}
                </Typography>
              </Box>
              <Button
                disabled={this.disabledHandler()}
                data-testid="createAccountbutton"
                onClick={(e: any) => {
                  this.handleAccountcreate(e);
                }}
                className={
                  this.disabledHandler()
                  ? classes.createAccountbuttonDisabled
                    : classes.createAccountbutton
                    
                }
              >
               {configJSON.labelButtontext}
              </Button>
            </Box>
          </Grid>

          <Modal
            open={this.state.sucessOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box>
                <Box className={classes.sucessImgBox}>
                  <img
                    alt="sucessimg"
                    className={classes.sucessImg}
                    src={sucessImg}
                  />
                  <Typography className={classes.sucessTextheading}>
                   {configJSON.sucessHeading}
                  </Typography>
                  <Typography className={classes.sucesstext}>
                   {configJSON.sucessText}
                  </Typography>
                  <Typography className={classes.sucesstext}>
                  {configJSON.sucessmessage}
                  </Typography>
                  <Button
                    className={classes.sucessContiuebutton}
                    onClick={this.navigatetosignin}
                  >
                   {configJSON.Backtologin}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={this.state.termsandconditionsOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
            <Grid container spacing={5}>
            <Grid container className={classes.accounttermsandconditionGrid}>
            <Grid className={classes.accountheadingBox} item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.accountheadingStyle}>{configJSON.labelLegalTermCondition}</Typography>
              <img
                data-testid="closeModaltermsandconditions"
                onClick={this.closeModaltermsandconditions}
                className={classes.accountbackArrow} src={backArrow} alt="backArrow" />

            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.accounttermsandconditionTextbox}>
              <Typography className={classes.accounttermsandconditionText}>
                {this.state.termsConditionstext}
              </Typography>

            </Grid>
            <Grid className={classes.accountheadingBox} item lg={12} md={12} sm={12} xs={12}>
              <Button
                data-testid="acceptTermsandconditionsButton"
                onClick={this.closeModalacceptTermsandconditions}
                className={classes.accountAcceptButton}>{configJSON.Accept}</Button>

            </Grid>

          </Grid>
        </Grid>
            </Box>
          </Modal>
       </Grid>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
  
}

// Customizable Area Start
export const webStyle: any = (theme: any) => ({
  logoGrid: {
    backgroundColor: "#0A4D68",
    backgroundPosition: "center",
    backgroundRepeat: " no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "50%",
      alignItems: "center",
    }
  },
  logoBox: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  logoBoxmobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },

  fieldsGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      borderStartStartRadius: "50px",
      borderStartEndRadius: "50px",
      alignItems: "start",
      justifyContent: "center",
      marginTop: "-10%",
      padding: "2%",
      paddingTop: "10%",
    }
  },
  checkbox: {
    color: "#5abd07 !important"
  },
  radioButton: {
    color: "#0A4D68 !important"
  },

  labourApptext: {
    fontSize: "55px",
    fontWeight: "600",
    fontFamily: "Inter",
    color: "white",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  accountText: {
    fontSize: "35px",
    fontWeight: "bold",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },
  haveAccounttext: {
    fontSize: "23px",
    marginTop: "1%",
    fontFamily: "Inter",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },
  radioButtonbox: {
    marginTop: "5%"
  },
  signIntext: {
    fontSize: "23px",
    color: "#0A4D68",
    paddingLeft: "10px",
    fontFamily: "Inter",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "grab"
  },
  supervisorRadiobutton: {
    fontSize: "20px",
    fontWeight: "500",
    '& span': {
      fontSize: '20px',
    }
  },
  labourRadiobutton: {
    fontSize: "20px",
    fontWeight: "500",
    '& span': {
      fontSize: '20px',
    }
  },
 
  textfieldTextstyle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#a6a6a6",
    paddingTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

  },
  erroricon: {
    position: "absolute",
    right: "2%"
  },
  textfieldBlock: {
    position: "relative"
  },
  textField: {
    fontSize: "20px",
    fontWeight: "500",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    '& div::after': {
      borderBottom: "2px solid #a6a6a6"
    },
    '& div::before': {
      borderBottom: "2px solid #a6a6a6"
    }
  },
  textFieldClass: {
    fontSize: "20px",
    fontWeight: "500",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    '& div::after': {
      borderBottom: "2px solid #a6a6a6"
    },
    '& div::before': {
      borderBottom: "2px solid #a6a6a6"
    },
  },
  textfieldBox: {
    marginTop: "2%"
  },
  logoImg: {
    width: "250px",
    height: "250px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },
  mobilelogoImg:{
    width: "200px",
    height: "200px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },
  checkboxStyle: {
    marginTop: "2%",
    display: "flex",
    textAlign: "center"
  },
  termsAndconditions: {
    fontSize: "17px",
    fontWeight: "500",
    color: "black",
    marginTop: "10px",
    textDecoration: "underline",
    cursor: "grab"
  },
  createAccountbutton: {
    width: "100%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "7%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    lineHeight: "normal",
    "&:hover": {
      width: "100%",
      color: "#fff !important",
      fontSize: "17px",
      marginTop: "7%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "15.5px 113.5px",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
    }
  },
  createAccountbuttonDisabled: {
    width: "100%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "7%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    opacity: "0.6",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    lineHeight: "normal",
  },
  popover_class: {
    marginTop: "10px",
    position: "absolute !important",
    top: "-20px",
    right: "5px",
    zIndex: "10"
  },
  errorMsgbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  errorIconblack: {
    paddingLeft: "20px"
  },
  errormsgstyle: {
    fontSize: "15px",
    color: "black",
    margin: "10px",
    marginLeft: "1px",
    paddingLeft: "2px",
    padding: "10px"
  },
  errormsgPsdstyle: {
    fontSize: "15px",
    color: "black",
    margin: "5px",
    marginLeft: "1px",
    paddingLeft: "2px",
    padding: "5px"
  },
  sucessImgBox: {
    textAlign: "center"
  },
  backArrowbox: {
    marginLeft: "3%",
    marginTop: "2%"
  },
  sucessOpenbackBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start"
  },
  sucessTextheading: {
    fontSize: "30px",
    fontWeight: "900",
    marginTop: "2%",
    color: "black",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  sucesstext: {
    fontSize: "18px",
    fontWeight: "500",
    color: "black",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

  },
  sucessContiuebutton: {
    padding: "1% 15% 1%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "3%",
    fontWeight: "600",
    textTransform: "capitalize",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    lineHeight: "normal",

    "&:hover": {

      color: "#fff !important",
      fontSize: "17px",
      marginTop: "3%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "1% 15% 1%",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
    }
  },
  accounttermsandconditionGrid: {
    margin: "5%"
  },
  accountheadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10%"
    },

  },
  accountbackArrow: {
    position: "absolute",
    left: "89px",
    [theme.breakpoints.down('md')]: {
      left: "19px",
    },
    [theme.breakpoints.down('xs')]: {
      left: "19px",
      height: "20px"
    },

  },
  accountheadingStyle: {
    fontSize: "32px",
    fontWeight: "700",
    fontFamily: 'Inter',
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
    },
  },
  accountAcceptButton: {
   
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "5%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    lineHeight: "normal",

    "&:hover": {
     
      color: "#fff !important",
      fontSize: "17px",
      marginTop: "5%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "15.5px 113.5px",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: "10%"
    },
  },
  accounttermsandconditionTextbox: {
    marginTop: "3%",
    borderRadius: "20px",
    border: "2px solid grey",
    overflowY: "auto",
    padding: "2%",
    height: "400px",
    boxShadow: "0px 0px 2px 2px grey",
    scrollbarWidth: 'thin', /* Fallback for Firefox */
    scrollbarColor: '#888 #f1f1f1', /* Thumb and track colors */
    [theme.breakpoints.down('xs')]: {
      marginTop: "10%"
    },

  },
  accounttermsandconditionText: {
    fontSize: "17px",
    fontWeight: "500",
    fontFamily: 'Inter',
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  }
});
export const EmailAccountRegistrationStyle = withStyles(webStyle)(
  EmailAccountRegistration
);


// Customizable Area End
