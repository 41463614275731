Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.loginAPiEndPoint = "bx_block_login/logins";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.placeHolderMobile = "Mobile";
exports.labelForgotPassword = "Forgot password ?";
exports.btnTxtEmailLogin = "LOGIN WITH EMAIL";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Mobile no. not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Log in";
exports.labelheading = "Forgot Password"
exports.labelSubheading = "We will send a link to your email please re-set your password"
exports.labelButtontext = "Sign in"
exports.labelnewUsertext = "New user?"
exports.labelnewRouteusertext = "Create an account"
exports.logoHeading = "Labour App"
exports.forgotPassword = "Forgot Password"
exports.Password = "Password"
exports.ResetnewPassword = "Reset your new password"
exports.contuniePhonenumber = "Continue With PhoneNumber"
exports.helpText = "Get help signing in"
exports.sucessHeading = "Login successful!"
exports.sucessText = " You can use these details to log back into the app."
exports.sucessmessage = "We will redirect you automatically in 5 seconds"
exports.Backtologin = "Continue"
// Customizable Area End
