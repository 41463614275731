import React from "react";

// Customizable Area Start
import "../assets/Css/SignInMobileNo.css";
import { emailIcon, groupIcon, vector, group2Icon } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import SignInWithNoController, { Props } from "./SignInWithNoController";

export default class SignInMobileNo extends SignInWithNoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-site">
        <section className="app-section">
          <div className="container-lg">
            <div className="app-wrapper">
              <div className="template-page">
                <div className="logo-wrapper">
                  <a href="#" className="logo">
                    <img src={groupIcon} alt="Labour-image" />
                  </a>
                  <a href="#" className="mobile-logo">
                    <img src={group2Icon} alt="g2" />
                  </a>
                  <div className="logo-title">
                    <h1>Labour App</h1>
                  </div>
                </div>
                <div className="copy-right">
                  <span>@2023LabourAllRightReserved</span>
                </div>
              </div>

              <div className="page-login">
                <div className="login-wrapper">
                  <div className="sing-in">
                    <h2 className="sign-text">Sign In</h2>
                    <h5>
                      New user?{" "}
                      <Link to="/EmailAccountRegistration">
                        <span className="account">Create an account</span>
                      </Link>
                    </h5>
                  </div>

                  <Formik
                    initialValues={{
                      phone: window.localStorage.getItem("phoneNo")
                        ? window.localStorage.getItem("phoneNo")
                        : ""
                    }}
                    validateOnBlur={false}
                    validationSchema={Yup.object().shape({
                      phone: Yup.string()
                        .required("Mobile number is Required")
                        .matches(
                          /^\d{10}$/,
                          "Please enter the correct mobile number"
                        )
                    })}
                    onSubmit={dataObject => {
                      this.doPhoneNumberLogin(dataObject);
                    }}
                    render={({
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      values
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <label htmlFor="phone">Mobile number </label>
                          <div className="inputContainer">
                            <input className="telCode" value="+91" />
                            <input
                              data-test-id="inputtest"
                              maxLength={10}
                              onKeyDown={this.preventETypeNumber}
                              autoComplete="false"
                              defaultValue={
                                window.localStorage.getItem("phoneNo") || ""
                              }
                              onChange={handleChange}
                              type="text"
                              name="phone"
                              id="phone"
                            />
                          </div>
                          {touched.phone && errors.phone && (
                            <div className="error-text">
                              <img
                                src={vector}
                                alt="error"
                                className="error-image"
                              />
                              <span>{errors.phone}</span>
                            </div>
                          )}

                          <div className="primary-button-wrapper">
                            <button
                              disabled={values.phone?.length !== 10}
                              data-test-id="formSubmitBtn"
                              type="submit"
                              className="primary-btn"
                            >
                              Send OTP
                            </button>
                          </div>
                          <div className="next">
                            <span className="border">or</span>
                          </div>

                          <div className="secondary-button-wrapper">
                            <Link
                              to="/EmailAccountLogin"
                              className="emailLinkBtn"
                            >
                              <img src={emailIcon} />
                              <span className="mail">Continue with email</span>
                            </Link>
                          </div>

                          <div>
                            <Link
                              to="/GetHelpSignIn"
                              className="get-help"
                              href="#"
                            >
                              Get help signing in
                            </Link>
                          </div>
                        </form>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
