import React from "react";

// Customizable Area Start
import "../assets/css/LandingPage.css";
import { logo, engIcon, EngGroupIcon, eng2 } from "./assets";
import { Link } from "react-router-dom";
import WithFooter from "../../../components/src/Withfooter.web";
// Customizable Area End

// Customizable Area Starts

// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GenSection = (leftDr: boolean, imageFile: any) => {
    return (
      <div className="our-benifits">
        <div className={leftDr ? "our-benifits-wrapper row-reverse" : "our-benifits-wrapper"}>
          <div className="about-benifits">
            <div className="benifits-text">
              <div className="pre-head">
                <h4>Our People</h4>
              </div>
              <div className="main-head">
                <h2>Benefits for Labour</h2>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, exercitationem deleniti recusandae totam voluptatem ipsam nemo facere corporis deserunt qui error laudantium, eius neque repellat aspernatur iure nobis rerum omnis dignissimos tempora! Voluptatem animi, aliquam molestias inventore nesciunt minus omnis mollitia in? Pariatur harum quo repellendus est culpa mollitia officiis maiores ipsam dolorum magnam molestias, voluptatem laudantium? Voluptate quam quis earum.{" "}
              </p>
            </div>
          </div>
          <div className="Benefits-image">
            <img src={imageFile} alt="image" width="100%" height="auto" />
          </div>
        </div>
        <div className={leftDr ? "our-benifits-background bg-benifits" : "our-benifits-background"} />
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-site labour-about-section">
        <header className="header-section">
          <div className="container">
            <div className="header-wrapper">
              <div className="header-logo-wrapper">
                <a href="#">
                  {" "}
                  <img src={logo} alt="logo" width="100%" height="auto" />
                  <span>Labour App</span>
                </a>
              </div>
              <div className="button-wrapper">
                <Link to="/EmailAccountRegistration" className="primary-btn">
                  SignUp/SignIn
                </Link>
              </div>
            </div>
          </div>
        </header>

        <div className="site-wrapper">
          <section className="title-heading-section">
            <div className="container">
              <div className="title-heading-wrapper">
                <h1>
                  Discover Your <span className="dream">Dream</span> Work
                </h1>
                <p>
                  Lorem ipsum astrong Hans Bjork. Bekinde Signe Bengtsson holiga
                  on komovangar embrejsa for att Roger Isaksson nonyvis sevyse
                  monomani: inklusive povis. Paragram tretaras, och lonyr
                  Vilhelm Strom megatism pohelogisk Sebastian Carlsson. Bibel
                  lan i etnosocial, filopol i sevosat ber heledes pir.{" "}
                </p>
              </div>
            </div>
          </section>

          <section className="our-benifits-section">
            <div className="container">
              {this.GenSection(false, EngGroupIcon)}
              {this.GenSection(true, eng2)}
              {this.GenSection(false, EngGroupIcon)}
            </div>
          </section>

          <section className="image-width-form-section">
            <div className="container-lg">
              <div className="image-width-form-wrapper">
                <div className="person-image">
                <img src={engIcon} alt="person" width="100%" height="auto" />
                </div>
                <div className="form-group">
                  <form>
                    <div className="form-name">
                      <h4>Contact Us</h4>
                      <span>Please enter your deatils</span>
                      <label htmlFor="name">Full name</label>
                      <input type="text" name="name" id="fname" />
                      <label htmlFor="mail">E-mail</label>
                      <input type="email" name="mail" id="e-mail" />
                      <label htmlFor="text">Message</label>
                      <textarea name="text" id="meassage" cols={30} rows={1}></textarea>
                      <div className="button-wrapper">
                        <a href="#" className="secondry-btn-wrapper">Submit</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default WithFooter(LandingPage);
// Customizable Area End
