Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeading = "Create an account";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and Conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.createaccountAPiEndPoint ="/account_block/accounts";
exports.termsandconditionsAPiEndPoint ="/account_block/accounts/term_and_condition";
exports.labelButtontext = "Create Account"
exports.labelnewUsertext = "Already have an account?"
exports.labelnewRouteusertext = "SignIn"
exports.logoHeading = "Labour App"
exports.forgotPassword = "Forgot Password"
exports.Password = "Password"
exports.FullName = " Full Name"
exports.MobileNumber = " MobileNumber"
exports.ConfirmPassword = "Confirm Password"
exports.ResetnewPassword = "Reset your new password"
exports.contuniePhonenumber = "Continue With PhoneNumber"
exports.helpText = "Get help signing in"
exports.sucessHeading = "Account Created Sucessfully"
exports.sucessText = "Please check your email for the verification link."
exports.sucessmessage = "By clicking on verification link you will be redirected to login page"
exports.Backtologin = "Continue"
exports.Accept = "Accept"
exports.PasswordCharacters = "8 Characters Long"
exports.PasswordUppercase = "Uppercase Letter"
exports.PasswordLowercase = "Lowercase Letter"
exports.PasswordOneNumber = "Atleast OneNumber"
exports.PasswordSpecial = "Special Character"


exports.validationApiMethodType = "GET";
// Customizable Area End
