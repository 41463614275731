import React from "react";

// Customizable Area Start
import "../assets/Css/LoginOTP.css";
import { groupIcon, processIcon } from "./assets";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import SignInWithNoController, { Props } from "./SignInWithNoController";

export default class LoginOTP extends SignInWithNoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-site">
        <section className="app-section otp-section">
          <div className="container-lg">
            <div className="app-wrapper">
              <div className="template-page">
                <div className="logo-wrapper">
                  <a href="#" className="logo">
                    <img src={groupIcon} alt="Labour-image" />
                  </a>
                  <div className="logo-title">
                    <h1>Labour App</h1>
                  </div>
                </div>
                <div className="copy-right">
                  <span>@2023LabourAllRightReserved</span>
                </div>
              </div>
              <div className="otp-page">
                <div className="opt-wrapper">
                  <div className="prev">
                    <Link to="/SignInMobileNo" className="back">
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="left-aero"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="main-text">
                    <h4 className="text-otp">Enter the OTP</h4>
                  </div>
                </div>
                <div className="number-wrapper">
                  <span className="t-num">
                    {`We sent it to +91${window.localStorage.getItem(
                      "phoneNo"
                    )}.`}{" "}
                    <Link to="/SignInMobileNo" className="changeRage">
                      Change
                    </Link>{" "}
                  </span>
                </div>
                <div className="text-code">
                  <h6 className="phone-code">Your 5-digit code</h6>
                  <OtpInput
                    data-test-id="otpInput"
                    value={this.state.otpValue}
                    onChange={this.handleChangeOTP}
                    numInputs={5}
                    focusStyle={webStyle.otpInputFocus}
                    inputStyle={webStyle.otpInput}
                    containerStyle={webStyle.otpContainer}
                    isInputNum={true}
                  />
                </div>
                <div className="primary-button-wrapper submition">
                  <button
                    data-test-id="submitBtn"
                    onClick={this.doOTPVerify}
                    disabled={this.state.otpValue.length !== 5}
                    className="submit"
                  >
                    {this.state.otpValue.length !== 5 ? "Verify OTP" : "Submit"}
                  </button>
                </div>
                <div className="resend-code">
                  <a data-test-id="resendOTPBtn" onClick={this.resendOTP}>
                    <img src={processIcon} alt="process" /> Resend code
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  otpInput: {
    margin: "0 5px",
    width: "100%",
    fontSize: "20px",
    border: "none",
    borderBottom: "1px solid #6d6d6d"
  },
  otpContainer: {
    gap: "18px",
    justifyContent: "space-between"
  },
  otpInputFocus: {
    outline: "none"
  }
};
// Customizable Area End
