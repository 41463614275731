export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const emailIcon = require("../assets/email.png");
export const groupIcon = require("../assets/group-42.png");
export const vector = require("../assets/vector.png");
export const group2Icon = require("../assets/group2.png");
export const signInSuccessful = require("../assets/signInSuccessful.png");
export const processIcon = require("../assets/process.png");



export const labourApplogo = require("../assets/Group 42@2x.png");
export const erroricon = require("../assets/bx_error 1.png");
export const errorIconblack = require("../assets/bx_error.png");
export const backArrow = require("../assets/back@2x.png");
export const sucessImg = require("../assets/SignIn_successful.png");
export const phone = require("../assets/ion_call.png");

