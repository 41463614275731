import React from "react";
// Customizable Area Start
import {
  Box,
  Modal,
  Button,
  Typography,
  TextField,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  FormControl,
  Checkbox
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import {
  erroricon,
  labourApplogo,
  phone,
  sucessImg,
  group2Icon
} from "./assets";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
import { configJSON } from "./SignInWithNoController";

// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./MobileAccountLoginController";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffcc00",
      contrastText: "#fff"
    }
  }
});

export default class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            className={classes.loginemaillogoGrid}
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.loginlogoBox}>
              <img
                className={classes.loginemaillogoImg}
                src={labourApplogo}
                alt="labourApplogo"
              />
              <Typography className={classes.loginemaillabourApptext}>
                {configJSON.logoHeading}
              </Typography>
              {/* <Box>
              <Typography className={classes.rightReservedtext}>@2023LabourAllRightReserved</Typography>

              </Box> */}
            </Box>
            <Box className={classes.loginlogoBoxmobile}>
              <img
                className={classes.loginemailMobilelogoImg}
                src={group2Icon}
                alt="labourApplogo"
              />
            </Box>
          </Grid>
          <Grid
            className={classes.loginfieldsGrid}
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.loginfieldsGridbox}>
              <Typography className={classes.loginsignIntext}>Sign In</Typography>
              <Typography className={classes.loginnewUsertext}>
                {configJSON.labelnewUsertext}
                <span
                  className={classes.loginsignUptext}
                  data-testid="navigatetosignup"
                  onClick={this.navigatetosignup}
                >
                  {configJSON.labelnewRouteusertext}
                </span>
              </Typography>
              <Box className={classes.logintextfieldBox}>
                <Typography className={classes.logintextfieldTextstyle}>
                  {configJSON.placeHolderEmail}
                </Typography>
                <TextField
                  name="email"
                  className={classes.logintextField}
                  data-testid="addemailChange"
                  onChange={(e: any) => {
                    this.addEmail(e);
                  }}
                />
              </Box>
              {this.state.errors.email ? (
                <Box className={classes.loginerrorMsgbox}>
                  <img
                    className={classes.loginerrorIconblack}
                    src={erroricon}
                    alt=""
                  />
                  <Typography className={classes.loginerrormsgstyle}>
                    {this.state.errors.email}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              <Box className={classes.logintextfieldBox}>
                <Box className={classes.logintextfieldBlock}>
                  <FormControl className={classes.logintextField1}>
                    <Typography className={classes.logintextfieldTextstyle}>
                      {configJSON.Password}
                    </Typography>
                    <Input
                      id="standard-adornment-password"
                      name="emailPassword"
                      data-testid="addpasswordChange"
                      type={
                        this.state.hidePassword.showPassword
                          ? "text"
                          : "password"
                      }
                      value={this.state.hidePassword.emailPassword}
                      onChange={(event: any) => {
                        this.handlePasswordChange(event, "emailPassword");
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            data-testid="handleClickShowPassword"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.hidePassword.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
              {this.state.errors.emailPassword ? (
                <Box className={classes.loginerrorMsgbox}>
                  <img
                    className={classes.loginerrorIconblack}
                    src={erroricon}
                    alt=""
                  />
                  <Typography className={classes.loginerrormsgstyle}>
                    {this.state.errors.emailPassword}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              <Box className={classes.loginforgotBox}>
                <Box className={classes.logincheckboxStyle}>
                  <Checkbox
                    className={classes.logincheckbox}
                    data-testid="checkBox"
                  />
                  <Typography
                    className={classes.logincheckBoxText}
                    data-testid="navigateTotermsandconditions"
                  >
                    Remember me?
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={classes.loginforgotPasswordtext}
                    data-testid="navigatetoForgotpassword"
                    onClick={this.navigatetoForgotpassword}
                  >
                    {" "}
                    {configJSON.forgotPassword}
                  </Typography>
                </Box>
              </Box>
              <Button
                disabled={this.state.errors.email !== "" || this.state.errors.emailPassword !== ""}
                className={
                  this.state.errors.email !== "" || this.state.errors.emailPassword !== ""
                    ?
                    classes.loginsendOtpbuttonDisabled :
                    classes.loginsendOtpbutton
                }
                data-testid="handleEmaillogin"
                onClick={(e: any) => {
                  this.handleEmaillogin(e);
                }}
              >
                {configJSON.labelButtontext}
              </Button>
              <Box className={classes.loginorBox}>
                <Box className={classes.loginhorizontalLine} />
                <Typography className={classes.loginor}>{configJSON.labelOr}</Typography>
                <Box className={classes.loginhorizontalLine} />
              </Box>
              <Button
                className={classes.logincontinueEmailbutton}
                data-testid="navigatetosigninwithmobile"
                onClick={this.navigatetosigninwithmobile}
              >
                {" "}
                <img className={classes.loginphone} src={phone} alt="phone" />
                {configJSON.contuniePhonenumber}
              </Button>
              <Typography
                className={classes.loginhelpTextstyle}
                onClick={this.navigatetoGethelp}
                data-testid="navigatetoGethelp"
              >
                {configJSON.helpText}
              </Typography>
            </Box>
          </Grid>
          <Modal
            open={this.state.loginsucessOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box>
                <Box className={classes.loginsucessImgBox}>
                  <img
                    alt="sucessimg"
                    className={classes.loginsucessImg}
                    src={sucessImg}
                  />
                  <Typography className={classes.loginsucessTextheading}>
                    {configJSON.sucessHeading}
                  </Typography>
                  <Typography className={classes.loginsucesstext}>
                    {configJSON.sucessText}
                  </Typography>
                  <Typography className={classes.loginsucesstext}>
                    {configJSON.sucessmessage}
                  </Typography>
                  <Button
                    className={classes.loginsucessContiuebutton}
                    onClick={this.navigatetoDashboard}
                  >
                    {configJSON.Backtologin}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
export const webStylelogin: any = (theme: any) => ({
  loginemaillogoGrid: {
    backgroundColor: "#0A4D68",
    backgroundPosition: "center",
    backgroundRepeat: " no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "50%",
      alignItems: "center"
    }
  },
  loginlogoBox: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  loginlogoBoxmobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  loginfieldsGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      borderStartStartRadius: "50px",
      borderStartEndRadius: "50px",
      alignItems: "start",
      justifyContent: "center",
      marginTop: "-10%",
      padding: "2%",
      paddingTop: "10%"
    }
  },
  loginemaillabourApptext: {
    fontSize: "55px",
    fontWeight: "600",
    fontFamily: "Inter",
    color: "white",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  loginsignIntext: {
    fontSize: "35px",
    fontWeight: "bold",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },
  loginnewUsertext: {
    fontSize: "23px",
    marginTop: "1%",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },
  logintextfieldBox: {
    marginTop: "2%"
  },

  loginsignUptext: {
    fontSize: "23px",
    color: "#0A4D68",
    fontWeight: "600",
    paddingLeft: "10px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "grab"
  },

  logintextfieldTextstyle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#a6a6a6",
    paddingTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  logintextField: {
    width: "100%",
    fontSize: "20px",
    fontWeight: "500",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    "& div::after": {
      borderBottom: "2px solid #a6a6a6"
    },
    "& div::before": {
      borderBottom: "2px solid #a6a6a6"
    }
  },
  logintextField1: {
    width: "100%",
    fontSize: "20px",
    fontWeight: "500",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    "& div::after": {
      borderBottom: "2px solid #a6a6a6"
    },
    "& div::before": {
      borderBottom: "2px solid #a6a6a6"
    }
  },

  loginemaillogoImg: {
    width: "250px",
    height: "250px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },
  loginemailMobilelogoImg: {
    width: "200px",
    height: "200px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },

  loginsendOtpbutton: {
    width: "100%",
    color: "#fff",
    fontSize: "17px",
    marginTop: "7%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 32.5px",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",

    "&:hover": {
      width: "100%",
      color: "#fff",
      fontSize: "17px",
      marginTop: "7%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "15.5px 32.5px",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter"
    }
  },
  loginsendOtpbuttonDisabled: {
    width: "100%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "7%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    opacity: "0.6",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  loginhorizontalLine: {
    width: "200px",
    height: "0px",
    border: "1px solid lightgray",
    [theme.breakpoints.down("sm")]: {
      width: "150px"
    }
  },
  loginorBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "6%",
    color: "lightgray",
    alignItems: "center"
  },
  loginor: {
    paddingLeft: "10px",
    paddingRight: "10px"
  },

  logincontinueEmailbutton: {
    width: "100%",
    color: "#0a4d68",
    fontSize: "17px",
    marginTop: "7%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 32.5px",
    borderRadius: "40px",
    border: "2px solid #0e688b",
    fontFamily: "Inter",

    "&:hover": {
      width: "100%",
      color: "#0a4d68",
      fontSize: "17px",
      marginTop: "7%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "15.5px 32.5px",
      borderRadius: "40px",
      border: "2px solid #0e688b",
      fontFamily: "Inter"
    }
  },
  loginhelpTextstyle: {
    color: "#0A4D68",
    paddingTop: "5%",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "grab"
  },
  logincheckboxStyle: {
    marginTop: "2%",
    display: "flex",
    textAlign: "center"
  },
  logincheckbox: {
    color: "#5abd07 !important"
  },
  loginforgotBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  loginforgotPasswordtext: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#0A4D68",
    marginTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "grab"
  },
  logincheckBoxText: {
    fontSize: "17px",
    marginTop: "6%",
    fontWeight: "600",
    color: "#6d6d6d",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  loginerroricon: {
    position: "absolute",
    right: "1%",
    bottom: "0px",
    top: "3px"
  },
  logintextfieldBlock: {
    position: "relative"
  },
  loginerrorMsgbox: {
    display: "flex",
    alignItems: "center"
  },

  loginerrormsgstyle: {
    fontSize: "16px",
    color: "red",
    margin: "5px",
    marginLeft: "1px",
    paddingLeft: "2px",
    padding: "5px"
  },
  loginsucessImgBox: {
    textAlign: "center"
  },
  loginbackArrowbox: {
    marginLeft: "3%",
    marginTop: "2%"
  },
  loginsucessTextheading: {
    fontSize: "30px",
    fontWeight: "900",
    marginTop: "2%",
    color: "black",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  loginsucesstext: {
    fontSize: "18px",
    fontWeight: "500",
    color: "black",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  loginsucessContiuebutton: {
    padding: "1% 15% 1%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "3%",
    fontWeight: "600",
    textTransform: "capitalize",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

    "&:hover": {
      color: "#fff !important",
      fontSize: "17px",
      marginTop: "3%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "1% 15% 1%",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal"
    }
  }
});
export const EmailLoginStyles = withStyles(webStylelogin)(EmailAccountLogin);
// Customizable Area End
