import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  FormControl,
  Modal

} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import {
  labourApplogo,
  erroricon,
  sucessImg,
  backArrow,
  group2Icon
} from "./assets";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
import { configJSON } from "./ForgotPasswordController";

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffcc00",
      contrastText: "#fff"
    }
  }
});

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid className={classes.newpasswordlogoGrid} item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.newpasswordlogoBox}>
              <img
                className={classes.newpasswordlogoImg}
                src={labourApplogo}
                alt="labourApplogo"
              />
              <Typography className={classes.newpasswordlabourApptext}>
                {configJSON.logoHeading}
              </Typography>
              {/* <Box>
              <Typography className={classes.rightReservedtext}>@2023LabourAllRightReserved</Typography>

              </Box> */}
            </Box>
            <Box className={classes.newpasswordlogoBoxmobile}>
              <img
                className={classes.newpasswordMobilelogoImg}
                src={group2Icon}
                alt="labourApplogo"
              />

            </Box>
             
          </Grid>
          <Grid
            className={classes.newpasswordfieldsGrid}
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.newpasswordfieldsGridbox}>
              <Box>
                <img
                  data-testid="navigatetosignup"
                  onClick={this.navigatetosignin}
                  className={classes.newpasswordbackArrow} src={backArrow} alt="backArrow" />
              </Box>
              <Typography className={classes.newpasswordaccountText}>
                {configJSON.ResetPassword}
              </Typography>
              <Typography className={classes.newpasswordhaveAccounttext}>
                {configJSON.ResetnewPassword}

              </Typography>

              <Box className={classes.newpasswordtextfieldBox}>

                <Box className={classes.newpasswordtextfieldBlock}>
                  <FormControl className={classes.newpasswordtextField}>
                    <Typography className={classes.newpasswordtextfieldTextstyle}>
                      {configJSON.Password}
                    </Typography>
                    <Input
                      id="standard-adornment-password"
                      name="password"
                      data-testid="addpasswordChange"
                      type={
                        this.state.hidePassword.showPassword
                          ? "text"
                          : "password"
                      }
                      value={this.state.hidePassword.password}
                      onChange={(event: any) => {
                        this.handlePasswordChange(event, "password");
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            data-testid="handleClickShowPassword"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.hidePassword.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
              {this.state.errors.password ? (
                <Box className={classes.newpassworderrorMsgbox}>
                  <img
                    className={classes.newpassworderrorIconblack}
                    src={erroricon}
                    alt=""
                  />
                  <Typography className={classes.newpassworderrormsgstyle}>
                    {this.state.errors.password}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              <Box className={classes.newpasswordtextfieldBox}>

                <Box className={classes.newpasswordtextfieldBlock}>
                  <Typography className={classes.newpasswordtextfieldTextstyle}>
                    {configJSON.Reenterpswd}
                  </Typography>
                  <FormControl className={classes.newpasswordtextField}>

                    <Input

                      id="standard-adornment-password"
                      name="confirm_Password"
                      data-testid="addconformpasswordChange"
                      type={
                        this.state.confirmHidepassword.showPassword
                          ? "text"
                          : "password"
                      }
                      value={this.state.confirmHidepassword.confirm_Password}
                      onChange={(event: any) => {
                        this.handleconformPasswordChange(event, "confirm_Password");
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            data-testid="handleClickShowconformPassword"
                            onClick={this.handleClickShowconformPassword}
                            onMouseDown={this.handleMouseDownconformPassword}
                          >
                            {this.state.confirmHidepassword.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
              {this.state.errors.confirm_Password ? (
                <Box className={classes.newpassworderrorMsgbox}>
                  <img
                    className={classes.newpassworderrorIconblack}
                    src={erroricon}
                    alt=""
                  />
                  <Typography className={classes.newpassworderrormsgstyle}>
                    {this.state.errors.confirm_Password}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              <Button
                data-testid="newPasswordbutton"
                onClick={(e: any) => {
                  this.handleEmailnewpassword(e);
                }}
                disabled={this.state.errors.email !== ""}
               
                className={
                  this.state.errors.email !== ""
                    ?
                    classes.newpasswordcreateAccountbuttonDisabled :
                    classes.newpasswordcreateAccountbutton
                }
                
              >
                {configJSON.labelButtontext}
              </Button>
              <Typography className={classes.newpasswordsigninBacktext}>{configJSON.labelnewUsertext} <span className={classes.newpasswordsigninRoutetext} data-testid="signinRoutetext" onClick={this.navigatetosignin}>{configJSON.labelnewRouteusertext}</span></Typography>
            </Box>
          </Grid>
          <Modal
            open={this.state.resetPasswordsucessOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box>
                <Box className={classes.newpasswordloginsucessImgBox}>
                  <img
                    alt="sucessimg"
                    className={classes.newpasswordloginsucessImg}
                    src={sucessImg}
                  />
                  <Typography className={classes.newpasswordloginsucessTextheading}>
                    {configJSON.sucessHeading}
                  </Typography>
                  <Typography className={classes.newpasswordloginsucesstext}>
                    {configJSON.sucessText}
                  </Typography>
                  <Typography className={classes.newpasswordloginsucesstext}>
                    {configJSON.sucessmessage}
                  </Typography>
                  <Button
                    data-testid="navigatetoDashboard"
                    className={classes.newpasswordloginsucessContiuebutton}
                    onClick={this.navigatetosignin}
                  >
                    {configJSON.Backtologin}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>


        </Grid>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
export const newpasswordwebStyle: any = (theme: any) => ({
  newpasswordlogoGrid: {
    backgroundColor: "#0A4D68",
    backgroundPosition: "center",
    backgroundRepeat: " no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "50%",
      alignItems: "center",
    }
  },
  newpasswordlogoBox: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  newpasswordlogoBoxmobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },

  newpasswordfieldsGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      borderStartStartRadius: "50px",
      borderStartEndRadius: "50px",
      alignItems: "start",
      justifyContent: "center",
      marginTop: "-10%",
      padding: "2%",
      paddingTop: "10%",
    }
  },
  newpasswordlabourApptext: {
    fontSize: "55px",
    fontWeight: "600",
    fontFamily: "Inter",
    color: "white",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  newpasswordaccountText: {
    fontSize: "35px",
    fontWeight: "bold",
    fontFamily: "Inter",
    marginTop: "3%",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },
  newpasswordhaveAccounttext: {
    fontSize: "19px",
    marginTop: "1%",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },

  newpasswordtextfieldTextstyle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#a6a6a6",
    paddingTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

  },
  newpassworderroricon: {
    position: "absolute",
    right: "2%"
  },
  newpasswordtextfieldBlock: {
    position: "relative"
  },
  newpasswordtextField: {
    fontSize: "20px",
    fontWeight: "500",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    '& div::after': {
      borderBottom: "2px solid #a6a6a6"
    },
    '& div::before': {
      borderBottom: "2px solid #a6a6a6"
    }
  },
  newpasswordtextfieldBox: {
    marginTop: "6%"
  },
  newpasswordlogoImg: {
    width: "250px",
    height: "250px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },
  newpasswordMobilelogoImg: {
    width: "200px",
    height: "200px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },
  newpasswordcreateAccountbutton: {
    width: "100%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "10%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStyle: "normal",
    letterSpacing: "normal",
    "&:hover": {
      width: "100%",
      color: "#fff",
      fontSize: "17px",
      marginTop: "10%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "15.5px 113.5px",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStyle: "normal",
      letterSpacing: "normal",
    }
  },
  newpasswordcreateAccountbuttonDisabled: {
    width: "100%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "10%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    opacity: "0.6",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  newpassworderrorMsgbox: {
    display: "flex",
    alignItems: "center"
  },
  newpassworderrormsgstyle: {
    fontSize: "16px",
    color: "red",
    margin: "5px",
    marginLeft: "1px",
    paddingLeft: "2px",
    padding: "5px"
  },
  newpasswordbackArrowbox: {
    marginLeft: "3%",
    marginTop: "2%"
  },
  newpasswordsigninBacktext: {
    fontSize: "25px",
    marginTop: "3%",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  },
  newpasswordsigninRoutetext: {
    fontSize: "25px",
    marginTop: "3%",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#0A4D68",
    cursor: "grab"
  },
  newpasswordloginsucessImgBox: {
    textAlign: "center"
  },
  newpasswordloginbackArrowbox: {
    marginLeft: "3%",
    marginTop: "2%"
  },
  newpasswordloginsucessTextheading: {
    fontSize: "30px",
    fontWeight: "900",
    marginTop: "2%",
    color: "black",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  newpasswordloginsucesstext: {
    fontSize: "18px",
    fontWeight: "500",
    color: "black",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  newpasswordloginsucessContiuebutton: {
    padding: "1% 15% 1%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "3%",
    fontWeight: "600",
    textTransform: "capitalize",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStyle: "normal",
    letterSpacing: "normal",

    "&:hover": {
      color: "#fff !important",
      fontSize: "17px",
      marginTop: "3%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "1% 15% 1%",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStyle: "normal",
      letterSpacing: "normal"
    }
  }

});
export const NewPasswordStyle = withStyles(newpasswordwebStyle)(
  NewPassword
);
// Customizable Area End
