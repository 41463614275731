import React from "react";

// Customizable Area Start
import "../assets/css/Dashboard.css";
import { mvb, dvb, settingIcon, officeIcon } from "./assets";
import WithFooter from "../../../components/src/Withfooter.web";
import WithHeader from "../../../components/src/WithHeader.web";
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Starts

// Customizable Area End

import DashboardController, { Props } from "./DashboardController";

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-site dashboard-page">
        <div className="site-wrapper">
          <section className="banner-section">
            <div className="container">
              <div className="banner-wrapper">
                <div className="banner-image">
                  <img
                    className="desktop-view"
                    src={dvb}
                    alt="banner"
                    width="100%"
                    height="auto"
                  />
                  <img
                    className="mobile-image"
                    src={mvb}
                    alt="banner"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div className="banner-details">
                  <div className="banner-title">
                    <h1>
                      Discover Your <br />
                      <span className="dream"> Dream </span>Work
                    </h1>
                  </div>
                  <p className="rtl">
                    Lorem ipsum tegon gotende ditt ande. Assa preppare mikrogyn,
                    dirade beliga. Anime prokrati, och dololigen. Elektrotet
                    bodidade i epirylig ifall b.
                  </p>
                  <form action="#" method="get">
                    <div className="search-form">
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="search-icon"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                      <input
                        type="text"
                        name="text-search"
                        id="text-search"
                        placeholder="Project title or keywords"
                      />
                      <div className="button-wrapper">
                        <a href="#" className="primary-btn">
                          Search
                        </a>
                      </div>
                    </div>
                  </form>
                  <div className="position-wrapper">
                    <span>Popular : Labour, Worker, Supervisor</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="our-project-section section">
            <div className="container">
              <div className="our-project-wrapper">
                <div className="project-head">
                  <div className="title-wrapper">
                    <h2>Explore by works</h2>
                  </div>
                  <div className="all-project">
                    <Link to="ViewAllWork">
                      <h3 className="cursor-pointer">
                        Show all works{" "}
                        <span className="right-aero">
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="right-side"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            />
                          </svg>
                        </span>
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="projects-group">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(() => {
                    return (
                      <div className="project-box">
                        <div className="project-icon">
                          <a href="#">
                            <img src={settingIcon} alt="asstes" />
                          </a>
                        </div>
                        <div className="project-name">
                          <span>
                            Modular Civil Contractor Building Construction
                            Services
                          </span>
                        </div>
                        <div className="total-projects">
                          <h5>
                            25 works available{" "}
                            <span>
                              <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="right-side"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                />
                              </svg>
                            </span>
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="letest-project-section">
            <div className="container">
              <div className="letest-project-wrapper">
                <div className="project-head">
                  <div className="title-wrapper">
                    <h2>Latest works open</h2>
                  </div>
                  <div className="all-project">
                    <h3>
                      Show all works{" "}
                      <span className="right-aero">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="right-side"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="frame-project-wrapper">
                  {[1, 2, 3, 4].map(() => {
                    return (
                      <div className="frame-box">
                        <div className="frame-icon">
                          <a href="#">
                            <img src={officeIcon} alt="icon" />
                          </a>
                        </div>
                        <div className="frame-details">
                          <div className="project-name">
                            <span>Office Building Projects Services</span>
                          </div>
                          <div className="project-time">
                            <span className="place-time">
                              <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="mdilocation"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                />
                              </svg>
                              Delhi
                            </span>
                            <span className="place-time">
                              <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="time-svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              Full time
                            </span>
                          </div>
                          <div className="button-wrapper">
                            <Link
                              to="/ViewWorkDetails"
                              className="secondry-btn"
                            >
                              View details
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default WithHeader(WithFooter(Dashboard));
// Customizable Area End
