import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import {
  labourApplogo,
  erroricon,
  backArrow,
  group2Icon
} from "./assets";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
import { configJSON } from "./ForgotPasswordController";

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffcc00",
      contrastText: "#fff"
    }
  }
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid className={classes.forgotPasswordlogoGrid} item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.forgotPasswordlogoBox}>
              <img
                className={classes.forgotPasswordlogoImg}
                src={labourApplogo}
                alt="labourApplogo"
              />
              <Typography className={classes.forgotPasswordlabourApptext}>
                {configJSON.logoHeading}
              </Typography>
            </Box>
            <Box className={classes.forgotPasswordlogoBoxmobile}>
              <img
                className={classes.forgotPasswordMobilelogoImg}
                src={group2Icon}
                alt="labourApplogo"
              />

            </Box>
          </Grid>
          <Grid
            className={classes.forgotPasswordfieldsGrid}
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.forgotPasswordfieldsGridbox}>
              <Box>
                <img
                  data-testid="navigatetosignin"
                  onClick={this.navigatetosignin}
                  className={classes.forgotPasswordbackArrow} src={backArrow} alt="backArrow" />
              </Box>
              <Typography className={classes.forgotPasswordaccountText}>
                {configJSON.labelheading}
              </Typography>
              <Typography className={classes.forgotPasswordhaveAccounttext}>
                {configJSON.labelSubheading}
              </Typography>
              <Box className={classes.forgotPasswordtextfieldBox}>
                <Typography className={classes.forgotPasswordtextfieldTextstyle}>
                  {configJSON.firstInputPlaceholder}
                </Typography>
                <Box className={classes.forgotPasswordtextfieldBlock}>
                  <TextField
                    name="email"
                    data-testid="addEmail"
                    onChange={(e: any) => {
                      this.addEmail(e);
                    }}
                    value={this.state.email}
                    className={classes.forgotPasswordtextField}
                  />

                </Box>
                {this.state.errors.email ? (
                  <Box className={classes.forgotPassworderrorMsgbox}>
                    <img
                      className={classes.forgotPassworderrorIconblack}
                      src={erroricon}
                      alt=""
                    />
                    <Typography className={classes.forgotPassworderrormsgstyle}>
                      {this.state.errors.email}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              <Button
                disabled={this.state.errors.email !== ""}
                data-testid="createAccountbutton"
                onClick={(e: any) => {
                  this.handleEmailforgotpassword(e)
                }}
                className={
                  this.state.errors.email !== ""
                    ?
                    classes.forgotPasswordcreateAccountbuttonDisabled :
                    classes.forgotPasswordcreateAccountbutton
                }
              >
                {configJSON.labelButtontext}
              </Button>
              <Typography className={classes.forgotPasswordsigninBacktext}>{configJSON.labelnewUsertext} <span className={classes.forgotPasswordsigninRoutetext} data-testid="navigatetosignup" onClick={this.navigatetosignup}>{configJSON.labelnewRouteusertext}</span></Typography>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
export const passwordWebstyle: any = (theme: any) => ({
  forgotPasswordlogoGrid: {
    backgroundColor: "#0A4D68",
    backgroundPosition: "center",
    backgroundRepeat: " no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "50%",
      alignItems: "center",
    }
  },
  forgotPasswordlogoBox: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  forgotPasswordlogoBoxmobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },

  forgotPasswordfieldsGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      borderStartStartRadius: "50px",
      borderStartEndRadius: "50px",
      alignItems: "start",
      justifyContent: "center",
      marginTop: "-10%",
      padding: "2%",
      paddingTop: "10%",
    }
  },
  forgotPasswordlabourApptext: {
    fontSize: "55px",
    fontWeight: "600",
    fontFamily: "Inter",
    color: "white",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  forgotPasswordaccountText: {
    fontSize: "35px",
    fontWeight: "bold",
    fontFamily: "Inter",
    marginTop: "3%",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },
  forgotPasswordhaveAccounttext: {
    fontSize: "17px",
    marginTop: "1%",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#6d6d6d"
  },

  forgotPasswordtextfieldTextstyle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#a6a6a6",
    paddingTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

  },
  forgotPassworderroricon: {
    position: "absolute",
    right: "2%"
  },
  forgotPasswordtextfieldBlock: {
    position: "relative"
  },
  forgotPasswordtextField: {
    fontSize: "20px",
    fontWeight: "500",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    '& div::after': {
      borderBottom: "2px solid #a6a6a6"
    },
    '& div::before': {
      borderBottom: "2px solid #a6a6a6"
    }
  },
  forgotPasswordtextfieldBox: {
    marginTop: "6%"
  },
  forgotPasswordlogoImg: {
    width: "250px",
    height: "250px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },
  forgotPasswordMobilelogoImg: {
    width: "200px",
    height: "200px",
    marginLeft: "13%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1%",
      marginTop: "10%"
    }
  },
  forgotPasswordcreateAccountbutton: {
    width: "100%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "10%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    "&:hover": {
      width: "100%",
      color: "#fff",
      fontSize: "17px",
      marginTop: "10%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "15.5px 113.5px",
      borderRadius: "40px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    }
  },
  forgotPasswordcreateAccountbuttonDisabled: {
    width: "100%",
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "10%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 113.5px",
    opacity: "0.6",
    borderRadius: "40px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  forgotPassworderrorMsgbox: {
    display: "flex",
    alignItems: "center"
  },
  forgotPassworderrormsgstyle: {
    fontSize: "16px",
    color: "red",
    margin: "5px",
    marginLeft: "1px",
    paddingLeft: "2px",
    padding: "5px"
  },
  forgotPasswordsigninBacktext: {
    fontSize: "22px",
    marginTop: "3%",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  },
  forgotPasswordsigninRoutetext: {
    fontSize: "22px",
    marginTop: "3%",
    fontWeight: "600",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#0A4D68",
    cursor: "grab"
  },

});
export const ForgotPasswordStyle = withStyles(passwordWebstyle)(
  ForgotPassword
);
// Customizable Area End
