import React from "react";

// Customizable Area Start
import "../assets/Css/LoginSuccessful.css";
import { signInSuccessful } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import SignInWithNoController, { Props } from "./SignInWithNoController";

export default class LoginSuccessful extends SignInWithNoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    let token = window.localStorage.getItem("token") || ''
    window.localStorage.setItem("Token", token);
    setTimeout(() => {
      this.props.navigation.navigate("Dashboard");
    }, 5000);
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <section className="sign-in-successful">
        <div className="container-lg">
          <div className="login-successful-wrapper">
            <div className="login-successful-image-wrapper">
              <img src={signInSuccessful} />
            </div>
            <div className="login-successful-heading-wrapper">
              <h1>Login successful!</h1>
            </div>
            <div className="login-successful-text-wrapper">
              <p>
                You can use these details to log back into the We will redirect
                you automatically in 5 seconds
              </p>
            </div>
            <div className="login-successful-btn-wrapper">
              <Link to='/Dashboard' className="Continue-btn">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </section>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
