import React from "react";

// Customizable Area Start
import "../assets/css/ViewWorkDetails.css";
import WithFooter from "../../../components/src/Withfooter.web";
import WithHeader from "../../../components/src/WithHeader.web";
// Customizable Area End

// Customizable Area Starts

// Customizable Area End

import DashboardController, { Props } from "./DashboardController";

export class ViewWorkDetails extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-site apply-for-work">
        <div className="site-wrapper-apply">
          <section className="template-section">
            <div className="container">
              <div className="template-wrapper">
                <div className="contractots-wrapper">
                  <h1>Peb & Steel Building Contractors</h1>
                  <div className="details-wrapper">
                    <span className="details">Bengaluru, Karnataka</span>
                  </div>
                  <div className="button-wrapper">
                    <a href="#" className="primary-btn">
                      Apply now
                    </a>
                  </div>
                </div>
                <div className="job-description">
                  <div className="template-heading">
                    <h2>Job description</h2>
                  </div>
                  <div className="about-data">
                    <div className="data-list">
                      <span className="data-type">Experience level:</span>
                      <span className="details">Expert</span>
                    </div>
                    <div className="data-list">
                      <span className="data-type">Experience length: </span>
                      <span className="details">5 years</span>
                    </div>
                    <div className="data-list">
                      <span className="data-type">Job type: </span>
                      <span className="details">Full-time</span>
                    </div>
                    <div className="data-list">
                      <span className="data-type">Application deadline: </span>
                      <span className="details">27 April 2023</span>
                    </div>
                    <div className="data-list">
                      <span className="data-type">Salary range: </span>
                      <span className="details">₹40,000 to ₹60,000</span>
                    </div>
                  </div>
                  <div className="Content">
                    <div className="template-heading">
                      <h2>Requirements</h2>
                      <p>
                        Lörem ipsum pohur Wilhelm Hellström, på bäk lagas
                        anaitet. Ontometer pneumanomi i demopod prev, krosk.
                        Amanda Arvidsson tempoaktiv renat Chris Strömberg,
                        desvision. Missade bengar dodulingar. Kohet Albin Blom
                        Carina Ström i mirat surtad. Parasport issa eung teled
                        decimis.
                      </p>
                      <p>
                        {" "}
                        Felicia Wallin proktig. Töturad sotrer, parastik att
                        Kristina Sundberg prenomi. Rer kopimism i endobel
                        kommatisk prerade. Neopod odeligt, alltså endoism vins i
                        biolog. Bening sonyna. Robin Norberg sebelt ter ifall
                        Jessica Johansson.
                      </p>
                      <p>
                        {" "}
                        Jent Gun Lind, Alexandra Lundström om por som dingen.
                        Hemiposam nitrebärade. Ida Holm Malin Månsson ultraling.
                        Lena Sandberg Sofia Berggren. Matilda Sundström renar.
                        Redade.{" "}
                      </p>
                      <p>
                        Dur dunade nirtad. Helena Axelsson bens. Reagänt ninat
                        liksom dihuda, nynde. Fångar kypregt. Plaling håssa
                        agnostifoni trisk. Pass portad preform terapatologi.{" "}
                      </p>
                      <p>
                        {" "}
                        Deplastisk dism. Olle Jonasson dekadeck, pohere. Bätusm
                        bent ahesarade terade. Vande androtion. Mobilblottare
                        dist nebeng anativ. Kungspudel pon.{" "}
                      </p>
                      <p>
                        Sahunis disk: i antinat. Rune Holmgren. Gigatöll Monica
                        Gustavsson fast terabälig. Kontranas semifoni,
                        stenopregt megaligen. Lall bebeliliga. Beng hågan
                        prefätred polyception i Felicia Gunnarsson.
                      </p>
                    </div>
                  </div>
                  <div className="Content">
                    <div className="template-heading">
                      <h2>Responsibilities</h2>
                      <p>
                        Lörem ipsum pohur Wilhelm Hellström, på bäk lagas
                        anaitet. Ontometer pneumanomi i demopod prev, krosk.
                        Amanda Arvidsson tempoaktiv renat Chris Strömberg,
                        desvision. Missade bengar dodulingar. Kohet Albin Blom
                        Carina Ström i mirat surtad. Parasport issa eung teled
                        decimis.
                      </p>
                      <p>
                        {" "}
                        Felicia Wallin proktig. Töturad sotrer, parastik att
                        Kristina Sundberg prenomi. Rer kopimism i endobel
                        kommatisk prerade. Neopod odeligt, alltså endoism vins i
                        biolog. Bening sonyna. Robin Norberg sebelt ter ifall
                        Jessica Johansson.
                      </p>
                      <p>
                        {" "}
                        Jent Gun Lind, Alexandra Lundström om por som dingen.
                        Hemiposam nitrebärade. Ida Holm Malin Månsson ultraling.
                        Lena Sandberg Sofia Berggren. Matilda Sundström renar.
                        Redade.{" "}
                      </p>
                      <p>
                        Dur dunade nirtad. Helena Axelsson bens. Reagänt ninat
                        liksom dihuda, nynde. Fångar kypregt. Plaling håssa
                        agnostifoni trisk. Pass portad preform terapatologi.{" "}
                      </p>
                      <p>
                        {" "}
                        Deplastisk dism. Olle Jonasson dekadeck, pohere. Bätusm
                        bent ahesarade terade. Vande androtion. Mobilblottare
                        dist nebeng anativ. Kungspudel pon.{" "}
                      </p>
                      <p>
                        Sahunis disk: i antinat. Rune Holmgren. Gigatöll Monica
                        Gustavsson fast terabälig. Kontranas semifoni,
                        stenopregt megaligen. Lall bebeliliga. Beng hågan
                        prefätred polyception i Felicia Gunnarsson.
                      </p>
                    </div>
                  </div>
                  <div className="Content">
                    <div className="template-heading">
                      <h2>Benefits</h2>
                      <p>
                        Lörem ipsum pohur Wilhelm Hellström, på bäk lagas
                        anaitet. Ontometer pneumanomi i demopod prev, krosk.
                        Amanda Arvidsson tempoaktiv renat Chris Strömberg,
                        desvision. Missade bengar dodulingar. Kohet Albin Blom
                        Carina Ström i mirat surtad. Parasport issa eung teled
                        decimis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default WithHeader(WithFooter(ViewWorkDetails));
// Customizable Area End
