import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeIndex: any;
  otpValue: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class SignInWithNoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOTPCallId: string = "";
  apiOTPVerifyCallId: string = "";
  apiResendOTPCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      activeIndex: null,
      otpValue: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResFordoPhoneNumberLogin(from, message);
    this.handleResForOTPVerify(from, message);
    this.handleResForResendOTP(from, message);
    // Customizable Area End
  }

  // Customizable Area Start

  //Web logic Function
  preventETypeNumber = (e: any) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Shift"
    ];
    return (
      (allowedKeys.includes(e.key) ? false : !/^[^A-Za-z\W_]+$/.test(e.key)) &&
      e.preventDefault()
    );
  };

  toggleAccordion = (index: any) => {
    if (this.state.activeIndex === index) {
      return this.setState({ activeIndex: null });
    }
    this.setState({ activeIndex: index });
  };

  handleChangeOTP = (value: any) => {
    this.setState({
      otpValue: value
    });
  };

  //API Function
  doPhoneNumberLogin = (dataObj: any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };
    const data = {
      attributes: {
        full_phone_number: "91" + dataObj.phone
      }
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/otps"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  doOTPVerify = () => {
    if (this.state.otpValue.length !== 5) {
      return ToastMessage("error", "Please enter OTP");
    }
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };
    const data = {
      token: window.localStorage.getItem("SignInToken"),
      otp_code: this.state.otpValue
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiOTPVerifyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/otp_confirmations"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resendOTP = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: window.localStorage.getItem("SignInToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResendOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/resend_otp"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //API Response Function
  handleResFordoPhoneNumberLogin = async (from: string, message: Message) => {
    if (
      this.getOTPCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.errors) {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      } else {
        window.localStorage.setItem("SignInToken", responseJson.meta.token);
        window.localStorage.setItem(
          "phoneNo",
          responseJson.data.attributes.full_phone_number.slice(2)
        );
        this.props.navigation.navigate("LoginOTP");
      }
    }
  };

  handleResForOTPVerify = async (from: string, message: Message) => {
    if (
      this.apiOTPVerifyCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.errors) {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      } else {
        window.localStorage.clear();
        ToastMessage("success", "OTP validation success");
        window.localStorage.setItem("token", responseJson.meta.token);
        this.props.navigation.navigate("LoginSuccessful");
      }
    }
  };

  handleResForResendOTP = async (from: string, message: Message) => {
    if (
      this.apiResendOTPCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.errors) {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      } else {
        ToastMessage("success", "OTP sent successfully!");
      }
    }
  };

  FormatError = (errors: any[]) => {
    let allerrors = "";
    errors.forEach(object => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          allerrors =
            allerrors.length <= 0 ? value : `${allerrors}{\n}${value}`;
        }
      });
    });
    return allerrors;
  };

  // Customizable Area End
}
