// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Download from "../../blocks/Download/src/Download";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import AppleLogin2 from "../../blocks/AppleLogin2/src/AppleLogin2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import Notifications from "../../blocks/notifications/src/Notifications";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import { EmailAccountRegistrationStyle } from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import { TermsandConditionsStyle } from "../../blocks/email-account-registration/src/TermsandConditions.web";
import { EmailLoginStyles } from '../../blocks/mobile-account-login/src/EmailAccountLogin.web'
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import { ForgotPasswordStyle } from '../../blocks/forgot-password/src/ForgotPassword.web'
import { NewPasswordStyle } from '../../blocks/forgot-password/src/NewPassword.web'
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import SignInMobileNo from "../../blocks/mobile-account-login/src/SignInMobileNo.web"
import GetHelpSignIn from "../../blocks/mobile-account-login/src/GetHelpSignIn.web"
import LoginSuccessful from "../../blocks/mobile-account-login/src/LoginSuccessful.web"
import LoginOTP from "../../blocks/mobile-account-login/src/LoginOTP.web"
import ViewWorkDetails from "../../blocks/dashboard/src/ViewWorkDetails.web"
import ViewAllWork from "../../blocks/dashboard/src/ViewAllWork.web"


const routeMap = {
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  AppleLogin2: {
    component: AppleLogin2,
    path: "/AppleLogin2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  ForgotPassword: {
    component: ForgotPasswordStyle,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPasswordStyle,
    path: "/NewPassword"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard",
    protected: true
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/ElasticSearch"
  },
  LandingPage: {
    component: LandingPage,
    path: "/",
    exact: true
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: "/PhotoLibrary3"
  },
  SignInMobileNo: {
    component: SignInMobileNo,
    path: "/SignInMobileNo"
  },
  GetHelpSignIn: {
    component: GetHelpSignIn,
    path: "/GetHelpSignIn"
  },
  LoginSuccessful: {
    component: LoginSuccessful,
    path: "/LoginSuccessful"
  },
  LoginOTP: {
    component: LoginOTP,
    path: "/LoginOTP"
  },
  EmailAccountLogin: {
    component: EmailLoginStyles,
    path: "/EmailAccountLogin"
  },
  TermsAndConditions: {
    component: TermsandConditionsStyle,
    path: "/termsandconditions"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistrationStyle,
    path: "/EmailAccountRegistration"
  },
  Home: {
    component: HomeScreen,
    path: '/HomeScreen'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  ViewWorkDetails: {
    component: ViewWorkDetails,
    path: "/ViewWorkDetails",
    protected: true
  },
  ViewAllWork: {
    component: ViewAllWork,
    path: "/ViewAllWork",
    protected: true
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
        />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View >
    );
  }
}

export default App;