import React from "react";

// Customizable Area Start
import "./WithHeader.css";
// @ts-ignore
import logo from "./logo.png";
// @ts-ignore
import mask from "./mask.png"
// @ts-ignore
import notification from './notification.png'
// @ts-ignore
import helpIcon from './assets/help.png'
// @ts-ignore
import iconx from './assets/iconx.png'
// @ts-ignore
import logoutIcon from './assets/logout.png'
// @ts-ignore
import settingIcon from './assets/setting.png'
// @ts-ignore
import vectorIcon from './assets/vector.png'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// Customizable Area End


// Customizable Area Start
const WithHeader = (WrappedComponent: any) => {
    return class WithHeader extends React.Component {
        constructor(props: any) {
            super(props);
        }

        state = {
            anchorEl: null
        }

        handleClose = () => {
            return this.setState({ anchorEl: false })
        }

        handleClick = () => {
            if (this.state.anchorEl) {
                return this.setState({ anchorEl: false })
            }
            this.setState({ anchorEl: true })
        };

        logout = () => {
            localStorage.clear();
            // @ts-ignore
            this.props.navigation.navigate("SignInMobileNo");
        };

        render() {
            return (
                <div>
                    <div className="main-site header-component">

                        <header className="header-section">
                            <div className="container">
                                <div className="header-wrapper">
                                    <div className="header-logo-wrapper">
                                        <a href="#"> <img src={logo} alt="logo" width="100%"
                                            height="auto" /></a>
                                        <span className="logo-name">Labour App</span>
                                    </div>
                                    <nav className="navigation">
                                        <ul className="nav-list">
                                            <li className="nav-item">
                                                <a href="#" className="nav-link active">Home</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link">Find projects</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link">Contact Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="group-wrapper">
                                        <div className="notification-wrapper">
                                            <a href="#">
                                                <img src={notification} alt="notification-1" width="100%"
                                                    height="auto" />
                                            </a>
                                        </div>
                                        <div className="user">
                                            <a onClick={this.handleClick}>
                                                <img src={mask} alt="mask-group"
                                                    width="100%" height="auto" /></a>

                                            {this.state.anchorEl &&
                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                    <div className="user-menu top-aero">
                                                        <div className="user-menu-list">
                                                            <a href="#"><img src={iconx} alt="icon" />My profile</a>
                                                        </div>
                                                        <div className="user-menu-list">
                                                            <a href="#"><img src={vectorIcon} alt="icon" />My jobs</a>
                                                        </div>
                                                        <div className="user-menu-list">
                                                            <a href="#"><img src={settingIcon} alt="icon" />Settings</a>
                                                        </div>
                                                        <div className="user-menu-list">
                                                            <a href="#"><img src={helpIcon} alt="icon" />Help centre</a>
                                                        </div>
                                                        <div className="user-menu-list">
                                                            <a onClick={this.logout}><img src={logoutIcon} alt="icon" />Sign Out</a>
                                                        </div>
                                                    </div>
                                                </ClickAwayListener>
                                            }
                                        </div>
                                        <div className="menu">
                                            <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                className="svg-icon">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                    </div>
                    <WrappedComponent />
                </div>
            );
        }
    };
};
// Customizable Area End

// Customizable Area Start
export default WithHeader
// Customizable Area End
