import React from "react";

// Customizable Area Start
import "../assets/Css/GetHelpSignIn.css";
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Start
const accordianData = [
  {
    No: 1,
    question: "Recover your password",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  },
  {
    No: 2,
    question: "What to do if you forget your username",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  },
  {
    No: 3,
    question: "Links in password reset email from labour app aren’t working",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  },
  {
    No: 4,
    question: "If you think your account has been hacked",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  },
  {
    No: 5,
    question: "What to do if the email address for your account was changed",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  },
  {
    No: 6,
    question:
      "What to do if your email address can’t be found during a password reset for your account",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  },
  {
    No: 7,
    question:
      "If you didn’t receive the password reset email that you requested from labour app",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  },
  {
    No: 8,
    question:
      "If you loose access to the email account or phone number that you used to create your account",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, error? lorem epsum is herer adefsdzcsf sfs"
  }
];

// Customizable Area End

import SignInWithNoController, { Props } from "./SignInWithNoController";

export default class GetHelpSignIn extends SignInWithNoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GenAccordion = (title: string, index: number) => {
    return (
      <div className="accordian-wrapper">

        <button
          data-test-id="toggleAccordionBtn"
          className={`accordion ${
            this.state.activeIndex === index ? "active" : ""
          }`}
          onClick={() => this.toggleAccordion(index)}
        >
          <p>{title}</p>
          <svg
            fill="currentColor"
            className="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </button>
        
        <div
          className={`${this.state.activeIndex === index ? "show" : "panel"}`}
        >
          <p className="rtl">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum,
            error? lorem epsum is herer adefsdzcsf sfs
          </p>
        </div>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <section className="signing-faqs">
        <div className="container">
          <div className="faq-wrapper get-help">
            <div className="faq-heading-wrapper">
              <div className="prev">
                <Link to="/SignInMobileNo" className="back">
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="left-aero"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>
                </Link>
              </div>
              <div className="faq-heading">
                <h1>Get help signing in</h1>
              </div>
            </div>

            <div className="faq-questions-wrapper">
              <h2 className="faq-questions-heading">
                Username and password troubleshooting
              </h2>

              {accordianData.slice(0, 4).map(val => {
                return this.GenAccordion(val.question, val.No);
              })}
            </div>

            <div className="faq-questions-wrapper">
              <h2 className="faq-questions-heading">Email Troubleshooting</h2>

              {accordianData.slice(4, 8).map(val => {
                return this.GenAccordion(val.question, val.No);
              })}
            </div>
          </div>
        </div>
      </section>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
